import React from 'react';
import '@fontsource/raleway/400.css';
import '@fontsource/open-sans/700.css';
import '../styles/globals.css';
import { FirebaseAppProvider, AuthProvider } from 'reactfire';
import {
  ChakraProvider,
  Box,
  CircularProgress,
  extendTheme,
} from '@chakra-ui/react';

import { app, auth } from '../utils/firebase';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const fonts = {
  heading: `'Open Sans', sans-serif`,
  body: `'Raleway', sans-serif`,
};

const styles = {
  global: () => ({
    body: {
      bg: 'gray-200',
    },
  }),
};

const theme = extendTheme({ colors, fonts, styles });

function MyApp({ Component }) {
  const Loading = (
    <Box display="flex" justifyContent="center" p={4}>
      <CircularProgress isIndeterminate color="green.300" />
    </Box>
  );

  return (
    <FirebaseAppProvider firebaseApp={app}>
      <AuthProvider sdk={auth}>
        <ChakraProvider theme={theme}>
          <React.Suspense fallback={Loading}>
            <Component />
          </React.Suspense>
        </ChakraProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  );
}

export default MyApp;
